export class Lock {
  public wait: Promise<void>;

  public unlock: () => void;

  private isLocked = false;

  constructor() {
    this.unlock = () => {};
    this.wait = Promise.resolve();
  }

  lock(): void {
    if (this.isLocked) {
      return;
    }

    this.isLocked = true;

    this.wait = new Promise((resolve) => {
      this.unlock = () => {
        this.isLocked = false;

        resolve();
      };
    });
  }
}

export const newLock = () => new Lock();
