import type { ReadonlyURLSearchParams } from 'next/navigation';

import { attachSearchToUrl } from 'shared/services/Links';
import { WEBSITE_URL_BY_LOCALE } from 'tools/locales';

export const getWebsiteURL = (
  locale: string,
  searchParams: ReadonlyURLSearchParams,
): string => {
  const url =
    locale in WEBSITE_URL_BY_LOCALE
      ? (WEBSITE_URL_BY_LOCALE[locale] as string)
      : 'sumup.com';

  return attachSearchToUrl(url, searchParams);
};
