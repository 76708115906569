import Tagger from '@elbwalker/tagger';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Anchor } from '@sumup/circuit-ui';
import { Grid, Row } from '@sumup/circuit-ui/legacy';
import { SumUpLogo } from '@sumup/icons';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import type { FC } from 'react';

import { Column } from 'shared/components/Column';
import { dispatchGoToWebsiteEvent } from 'shared/services/tracker/events';

import { getWebsiteURL } from './ShopHeaderService';

export type Props = {
  isLogoClickable: boolean;
};

const HEADER_HEIGHT = 60;
const LOGO_SIZE = 96;

const tagger = Tagger();

const Container = styled.header`
  // set z-index to absolute so the shadows at the bottom won't be hidden
  position: relative;
  z-index: var(--cui-z-index-absolute);

  height: ${HEADER_HEIGHT}px;
  width: 100%;
  box-shadow: var(--cui-border-subtle-disabled) 0px 0px 0px 1px,
    var(--cui-border-subtle-disabled) 0px 0px 1px 0px,
    var(--cui-border-subtle-disabled) 0px 2px 2px 0px;
  display: flex;
  align-items: center;
`;

const StyledCol = styled(Column)(
  ({ theme }) => css`
    ${theme.mq.untilKilo} {
      padding: 0 var(--cui-spacings-mega);
    }
  `,
);

/**
 * As discussed in the ticket, Logo will stay unclickable
 * until further verification.
 * Ref: https://sumupteam.atlassian.net/browse/ACQJ-362
 */
export const StyledSumUpLogo = styled(SumUpLogo)`
  height: ${LOGO_SIZE}px;
  width: ${LOGO_SIZE}px;
  vertical-align: middle;
`;

const StyledAnchor = styled(Anchor)(
  () => css`
    text-decoration: none;
    color: inherit;
    &:active,
    &:visited,
    &:visited:hover,
    &:hover,
    &:focus {
      text-decoration: none;
      color: inherit;
    }
  `,
);

export const ShopHeader: FC<Props> = (props) => {
  const { locale } = useRouter();
  const searchParams = useSearchParams();

  const websiteUrl = getWebsiteURL(locale, searchParams);
  const { isLogoClickable } = props;

  return (
    <Container>
      <Grid>
        <Row>
          <StyledCol span="10">
            {isLogoClickable ? (
              <StyledAnchor
                href={`https://${websiteUrl}`}
                onClick={() => {
                  void dispatchGoToWebsiteEvent({ link: websiteUrl });
                }}
                {...tagger.entity('button')}
                {...tagger.action('click', 'clicked')}
                {...tagger.property('button', 'option', websiteUrl)}
                aria-label="logo"
              >
                <StyledSumUpLogo
                  {...tagger.property(
                    'button',
                    'button_description',
                    'sumup_logo',
                  )}
                />
              </StyledAnchor>
            ) : (
              <StyledSumUpLogo />
            )}
          </StyledCol>
        </Row>
      </Grid>
    </Container>
  );
};
