import { tracker } from 'shared/services/tracker/tracker';
import { EVENTS } from 'shared/services/tracker/types';

import * as handlers from './handlers';

const {
  SIGNUP,
  LOGIN,
  PURCHASE,
  GO_TO_ACTIVATION,
  GO_TO_CHECKOUT,
  ADDRESS_SUGGESTION_SELECTED,
  SKIP_SHOP,
  MORE_INFO,
  PROCEED_AS_REGISTERED_USER,
  GO_TO_PAYMENT,
  GO_TO_CART,
  ADD_PRODUCT,
  VISITED_PRODUCT_DETAILS_PAGE,
  VISITED_PRODUCT_SELECTION_PAGE,
} = EVENTS;

tracker.listen(SIGNUP, handlers.signupHandler);
tracker.listen(LOGIN, handlers.loginHandler);
tracker.listen(PURCHASE, handlers.purchaseHandler);
tracker.listen(GO_TO_ACTIVATION, handlers.goToActivationHandler);
tracker.listen(GO_TO_CHECKOUT, handlers.goToCheckoutHandler);
tracker.listen(GO_TO_CART, handlers.goToCartHandler);
tracker.listen(ADD_PRODUCT, handlers.addProductHandler);
tracker.listen(PROCEED_AS_REGISTERED_USER, handlers.proceedAsRegisteredUser);
tracker.listen(
  ADDRESS_SUGGESTION_SELECTED,
  handlers.addressSuggestionSelectedHandler,
);
tracker.listen(SKIP_SHOP, handlers.skipShopHandler);
tracker.listen(MORE_INFO, handlers.moreInfoHandler);
tracker.listen(GO_TO_PAYMENT, handlers.goToPaymentHandler);
tracker.listen(
  VISITED_PRODUCT_DETAILS_PAGE,
  handlers.productDetailsPageViewHandler,
);
tracker.listen(
  VISITED_PRODUCT_SELECTION_PAGE,
  handlers.productSelectionPageViewHandler,
);
