import { STORE_STORAGE_PREFIX } from 'shared/constants/FeatureFlags';

export const SALES_CHANNEL_AUTH_TOKEN = 'SALES_CHANNEL_AUTH_TOKEN';
export const PLACED_CLAYER_ORDER_ID = 'PLACED_CLAYER_ORDER_ID';
export const CLAYER_ORDER_ID = 'CLAYER_ORDER_ID';
export const CLAYER_QUERY_ORDER_ID = 'cl_order_id';
export const ORDER_LOCALE = 'ORDER_LOCALE';
export const ORDER_CATALOG = 'ORDER_CATALOG';
export const MERCHANT_CODE = 'MERCHANT_CODE';
export const MERCHANT_ID = 'MERCHANT_ID';
export const MERCHANT_PROFILE = 'MERCHANT_PROFILE';
export const SALES_CHANNEL_COUNTRY = 'SALES_CHANNEL_COUNTRY';
export const BUSINESS_ACCOUNT_MODAL_IS_COMPLETED =
  'BUSINESS_ACCOUNT_MODAL_IS_COMPLETED';
export const TRACKED_PURCHASES = `${STORE_STORAGE_PREFIX}TRACKED_PURCHASES`;
