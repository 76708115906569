import type { ReadonlyURLSearchParams } from 'next/navigation';

export const attachSearchToUrl = (
  url: string,
  search: ReadonlyURLSearchParams,
): string => {
  if (!url || !search) {
    return url;
  }

  const [urlPath, urlSearch] = url.split('?');
  const readableSearchParams = new URLSearchParams(search);
  const urlParams = new URLSearchParams(urlSearch);

  // url params take precedence over search params
  urlParams.forEach((_, key) => {
    if (readableSearchParams.has(key)) {
      readableSearchParams.delete(key);
    }
  });

  const newParams = new URLSearchParams([
    ...readableSearchParams,
    ...urlParams,
  ]);
  const divider = newParams.toString().length > 0 ? '?' : '';

  return `${urlPath}${divider}${newParams.toString()}`;
};
