import type {
  EventTags,
  OptimizelyUserContext,
} from '@optimizely/optimizely-sdk';

import { mapNonCrTrackingId } from 'productSelection/components/ProductCard/services/tracking';
import { newLock, type Lock } from 'shared/services/Lock';
import logger from 'shared/services/logger';
import type {
  AddressSuggestionSelectedCustomData,
  AuthEventCustomData,
  GoToActivationCustomData,
  GoToCheckoutCustomData,
  GoToPaymentCustomData,
  ManageProductCustomData,
  MoreInfoCustomData,
  ProceedAsRegisteredUserCustomData,
  PurchaseEventCustomData,
  SkipShopEventCustomData,
} from 'shared/services/tracker/types';

interface OptimizelyTrack {
  eventKey: OptimizelyEventName;
  eventTags?: EventTags;
}

type OptimizelyEventName = (typeof OPTIMIZELY_EVENT_NAMES)[number];

const OPTIMIZELY_EVENT_NAMES = [
  'signup',
  'login',
  'purchase',
  'go_to_activation',
  'go_to_checkout',
  'address_suggestion_selected',
  'skip_shop',
  'more_info_click',
  'proceed_as_registered_user',
  'go_to_payment',
  'addToCart',
  'goToCart',
  'visited_product_details_page',
  'catalogue_explore_non_cr_product',
  'visited_product_selection',
] as const;

type TrackingContext = {
  user: OptimizelyUserContext | null;
  isEnabled: boolean;
  readyLock?: Lock;
};

// This is a singleton that will be used to store the tracking context
// for the current request.
// The context will be set by the ensureBrowserSessionCreated function
// in the useOptimize hook, which is called on every page load.
const CtxSingleton: TrackingContext = {
  user: null,
  isEnabled: true,
  readyLock: newLock(),
};

CtxSingleton.readyLock.lock();

export function registerTrackingContext(ctx: TrackingContext): void {
  CtxSingleton.user = ctx.user;
  CtxSingleton.isEnabled = ctx.isEnabled;
  CtxSingleton.readyLock.unlock();
}

async function optimizelyTrack({
  eventKey,
  eventTags,
}: OptimizelyTrack): Promise<void> {
  await CtxSingleton.readyLock.wait;

  if (!CtxSingleton.isEnabled) {
    logger
      .withContext({ extra: { eventKey, eventTags } })
      .info('Optimizely event blocked');

    return;
  }

  if (!CtxSingleton.user) {
    logger
      .withContext({ extra: { eventKey, eventTags } })
      .error(new Error('Unable to retrieve Optly user'));

    return;
  }

  CtxSingleton.user.trackEvent(eventKey, eventTags);
}

export function signupHandler(customData: AuthEventCustomData): void {
  const { merchantCode, orderId } = customData;
  const eventTags = {
    merchantCode,
    order_id: orderId,
  };

  void optimizelyTrack({
    eventKey: 'signup',
    eventTags,
  });
}

export function loginHandler(customData: AuthEventCustomData): void {
  const { merchantCode, orderId } = customData;
  const eventTags = {
    merchantCode,
    order_id: orderId,
  };

  void optimizelyTrack({
    eventKey: 'login',
    eventTags,
  });
}

export function purchaseHandler(customData: PurchaseEventCustomData): void {
  const { merchantCode, order, paymentMethod } = customData;
  const eventTags = {
    products: order.products.reduce(
      (acc, { code, trackingId }) => `${acc}${trackingId || code}|`,
      '|',
    ),
    productsQuantity: order.products.reduce(
      (a, { quantity }) => a + quantity,
      0,
    ),
    paymentType: paymentMethod,
    merchantCode,
    revenue: Math.floor(order.total * 100),
    order_id: order.id,
  };

  void optimizelyTrack({
    eventKey: 'purchase',
    eventTags,
  });
}

export function goToCheckoutHandler(customData: GoToCheckoutCustomData): void {
  const { orderId, merchantCode } = customData;
  const eventTags = {
    order_id: orderId,
    merchantCode,
  };

  void optimizelyTrack({
    eventKey: 'go_to_checkout',
    eventTags,
  });
}

export function goToCartHandler(): void {
  void optimizelyTrack({
    eventKey: 'goToCart',
  });
}

export function addProductHandler(customData: ManageProductCustomData): void {
  const { country, merchantCode, product } = customData;
  const eventTags = {
    country,
    merchantCode,
    productName: product.trackingId || product.code,
  };

  void optimizelyTrack({
    eventKey: 'addToCart',
    eventTags,
  });
}

export function proceedAsRegisteredUser(
  customData: ProceedAsRegisteredUserCustomData,
): void {
  const { merchantCode, orderId } = customData;
  const eventTags = {
    merchantCode,
    order_id: orderId,
  };

  void optimizelyTrack({
    eventKey: 'proceed_as_registered_user',
    eventTags,
  });
}

export function goToActivationHandler(
  customData: GoToActivationCustomData,
): void {
  const { merchantCode, orderId, country } = customData;
  const eventTags = {
    merchantCode,
    order_id: orderId,
    country,
  };

  void optimizelyTrack({
    eventKey: 'go_to_activation',
    eventTags,
  });
}

export function addressSuggestionSelectedHandler(
  customData: AddressSuggestionSelectedCustomData,
): void {
  const { orderId, merchantCode } = customData;
  const eventTags = {
    merchantCode,
    order_id: orderId,
  };

  void optimizelyTrack({
    eventKey: 'address_suggestion_selected',
    eventTags,
  });
}

export function skipShopHandler(
  customData: SkipShopEventCustomData = {},
): void {
  const { label, merchantCode, orderId, context, trackingId } = customData;
  const eventTags = {
    merchantCode,
    order_id: orderId,
  };

  void optimizelyTrack({
    eventKey: 'skip_shop',
    eventTags: {
      ...eventTags,
      ...(label && { product: label }),
    },
  });

  const remappedId = mapNonCrTrackingId(trackingId);
  if (context === 'product_card' && remappedId) {
    void optimizelyTrack({
      eventKey: 'catalogue_explore_non_cr_product',
      eventTags: {
        destination: remappedId,
      },
    });
  }
}

export function moreInfoHandler(customData: MoreInfoCustomData): void {
  const { productName, orderId, merchantCode } = customData;
  const eventTags = {
    merchantCode,
    order_id: orderId,
    productName,
  };

  void optimizelyTrack({
    eventKey: 'more_info_click',
    eventTags,
  });
}

export function goToPaymentHandler(customData: GoToPaymentCustomData): void {
  const { merchantCode, order } = customData;
  const eventTags = {
    merchantCode,
    order_id: order.id,
  };

  void optimizelyTrack({
    eventKey: 'go_to_payment',
    eventTags,
  });
}

export function productDetailsPageViewHandler(): void {
  void optimizelyTrack({
    eventKey: 'visited_product_details_page',
    eventTags: { source: 'storefront' },
  });
}

export function productSelectionPageViewHandler(): void {
  void optimizelyTrack({
    eventKey: 'visited_product_selection',
    eventTags: {
      source: 'storefront',
    },
  });
}
